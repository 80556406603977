const urlJoin = require('url-join');

const siteUrl = 'https://moin.bz';
const appUrl = 'https://app.moin.bz/app';
const docUrl = 'https://docs.moin.bz/';
const appLoginUrl = 'https://app.moin.bz/api/auth/login';

const ogImageLink = urlJoin(siteUrl, '/img/fb-share.png');
const logoIconImageLink = urlJoin(siteUrl, '/img/logo-icon.png');

const EN_LOCALE = 'en';
const ZH_LOCALE = 'zh';
const ZH_HANT_LOCALE = 'zh-hant';
const locales = [EN_LOCALE, ZH_HANT_LOCALE];
const defaultLocale = EN_LOCALE;

module.exports = {
    siteUrl,
    appUrl,
    docUrl,
    appLoginUrl,
    ogImageLink,
    logoIconImageLink,
    locales,
    defaultLocale,
    EN_LOCALE,
    ZH_LOCALE,
    ZH_HANT_LOCALE,
};
