const isUrl = require('is-url');
const urlJoin = require('url-join');
const { siteUrl, defaultLocale } = require('../config');
const { addTrailingSlash } = require('./index');

exports.blogPage = () => '/blog';
exports.getDocPage = (url) => `/docs/${url}/`;
exports.getBlogPage = (url) => `/blog/${url}/`;
exports.getFullUrl = (path) => addTrailingSlash(urlJoin(siteUrl, path));

exports.addLocaleUrl = (locale, url) => {
    if (isUrl(url)) {
        return addTrailingSlash(url);
    } if (locale === defaultLocale) {
        return addTrailingSlash(url);
    }
    return addTrailingSlash(urlJoin(`/${locale}`, url));
};
