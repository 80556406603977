import urlJoin from 'url-join';
import {
    defaultLocale, EN_LOCALE, siteUrl, ZH_LOCALE, ZH_HANT_LOCALE,
} from '../config';
import { genBlogJsonLd, genDefaultJsonLd } from './jsonld';
import { addLocaleUrl, getFullUrl } from './url';

const siteName = {
    [EN_LOCALE]: 'moin!',
    [ZH_HANT_LOCALE]: 'moin!',
};

const genHreflang = (pagePath) => [
    {
        lang: 'x-default',
        href: getFullUrl(addLocaleUrl(EN_LOCALE, pagePath)),
    },
    {
        lang: EN_LOCALE,
        href: getFullUrl(addLocaleUrl(EN_LOCALE, pagePath)),
    },
    {
        lang: ZH_LOCALE,
        href: getFullUrl(addLocaleUrl(ZH_HANT_LOCALE, pagePath)),
    },
];

const appendSiteName = (text, locale = defaultLocale) => `${text} | ${siteName[locale]}`;

const genIndex = (locale = defaultLocale) => {
    if (locale === EN_LOCALE) {
        return {
            title: appendSiteName('Customizable message platform for Startups & SMB’s', locale),
            description: 'moin is a feedback tool which helps you collect customers’ feedback to better understand customer needs and fullfill their requests',
        };
    } if (locale === ZH_HANT_LOCALE) {
        return {
            title: appendSiteName('輕鬆為你企業網站、新創公司或是個人網站搜集用戶回饋', locale),
            description: 'moin 幫助台灣的中小企業蒐集顧客的回饋，讓你更了解顧客並滿足顧客的需求。高度可客製化的回饋問題讓非程式專業背景的中小企業主輕鬆好上手。',
        };
    }
};

const genDocIndex = (locale = defaultLocale) => {
    if (locale === EN_LOCALE) {
        return {
            title: appendSiteName('Documentation', locale),
            description: 'moin Documentation page',
        };
    } if (locale === ZH_HANT_LOCALE) {
        return {
            title: appendSiteName('使用教學', locale),
            description: 'moin 使用教學',
        };
    }
};

const genBlogIndex = (locale = defaultLocale) => {
    if (locale === EN_LOCALE) {
        return {
            title: appendSiteName('Blog', locale),
            description: 'moin’s Blog page',
        };
    } if (locale === ZH_HANT_LOCALE) {
        return {
            title: appendSiteName('部落格', locale),
            description: 'moin 的部落格',
        };
    }
};

const genDocPage = (locale = defaultLocale, meta) => ({
    title: appendSiteName(meta.post.title, locale),
    description: meta.post.subtitle,
});

const genPrivacyPage = () => ({
    title: appendSiteName('Privacy', defaultLocale),
    description: 'moin privacy policy',
});

const genDefault = (locale = defaultLocale) => ({
    title: appendSiteName('Customizable message platform for Startups & SMB’s', locale),
    description: 'moin is a feedback tool which helps you collect customers’ feedback to better understand customer needs and fullfill their requests',
});

const genSEO = ({
    pagePath,
    locale,
    url,
    meta,
    hasHreflang = false,
}) => {
    let seo;
    let jsonld = genDefaultJsonLd();
    let hreflangs = [];
    const pageUrl = urlJoin(siteUrl, url, url !== '/' ? '/' : '');
    if (hasHreflang) {
        hreflangs = genHreflang(pagePath);
    }

    switch (pagePath) {
    case '/':
        seo = genIndex(locale);
        break;

    case '/docs/': {
        seo = genDocIndex(locale);
        break;
    }

    case '/blog/': {
        seo = genBlogIndex(locale);
        break;
    }

    case 'doc-page': {
        const { post } = meta;
        seo = genDocPage(locale, meta);
        jsonld = [
            ...jsonld,
            genBlogJsonLd({
                url: pageUrl,
                headline: post.title,
                alternativeHeadline: post.subtitle,
                inLanguage: locale,
                dateCreated: post.createdDate,
                datePublished: post.createdDate,
                dateModified: post.createdDate,
            }),
        ];
        break;
    }

    case 'blog-page': {
        const { post } = meta;
        seo = genDocPage(locale, meta);
        jsonld = [
            ...jsonld,
            genBlogJsonLd({
                url: pageUrl,
                headline: post.title,
                alternativeHeadline: post.subtitle,
                inLanguage: locale,
                dateCreated: post.dateCreated,
                datePublished: post.datePublished,
                dateModified: post.dateModified,
                articleBody: post.excerpt,
            }),
        ];

        break;
    }

    case 'privacy': {
        seo = genPrivacyPage();
        break;
    }

    default:
        seo = genDefault(locale);
        break;
    }
    return {
        ...seo,
        url: pageUrl,
        jsonld,
        hreflangs,
    };
};

export default genSEO;
