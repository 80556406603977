import React from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import SEO from './SEO';
import catalogEN from '../locales/en/messages';
import catalogZhHant from '../locales/zh-hant/messages';
import genSEO from '../utils/seo';
import { EN_LOCALE, ZH_HANT_LOCALE } from '../config';
import GlobalStyles from './styles/GlobalStyles';

const Layout = ({ children, seo, locale }) => {
    i18n.load({
        [EN_LOCALE]: catalogEN.messages,
        [ZH_HANT_LOCALE]: catalogZhHant.messages,
    });
    i18n.activate(locale);

    return (
        <I18nProvider
            i18n={i18n}
        >
            <GlobalStyles />
            {seo ? <SEO {...genSEO(seo)} /> : null}
            <div css={{ backgroundColor: '#fdf8f4' }}>
                {children}
            </div>
        </I18nProvider>
    );
};

export default Layout;
