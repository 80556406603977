import React from 'react';
import Helmet from 'react-helmet';
import urlJoin from 'url-join';
import { useLingui } from '@lingui/react';

import {
    withoutTrailing, addTrailingSlash,
} from '../utils';

import { ogImageLink } from '../config';

const ciaociaoID = {
    en: 'Ms9yPRC6a3',
    'zh-hant': 'bSxp14zVuh',
};

const SEO = ({
    title, description, url, script, noscript, hreflangs = [], vendorCSS = [], vendorJS = [], jsonld,
}) => {
    const { i18n } = useLingui();
    const { locale } = i18n;

    return (
        <Helmet defer={false} htmlAttributes={{ lang: locale }}>
            <title>{title}</title>
            <meta name="description" content={description} />
            {/* OpenGraph tags */}
            <meta property="og:url" content={url} />
            <meta property="og:title" content={title} />
            <meta property="og:description" content={description} />
            <meta property="og:image" content={ogImageLink} />
            <meta property="og:image:width" content="1208" />
            <meta property="og:image:height" content="636" />
            <meta property="og:type" content="website" />
            {/* Twitter Card tags */}
            {/* <meta name="twitter:card" content="summary_large_image" />
          <meta
            name="twitter:creator"
            content="evertoolapp"
          />
          <meta name="twitter:title" content={title} />
          <meta name="twitter:description" content={subtitle} />
          <meta name="twitter:image" content={imageUrl} /> */}
            {hreflangs.map((item) => (
                <link
                    key={item.lang}
                    rel="alternate"
                    hrefLang={item.lang}
                    href={item.href}
                />
            ))}
            {vendorCSS.map((css) => (
                <link rel="stylesheet" key={css.href} href={css.href} />
            ))}
            {vendorJS.map((js) => (
                <script key={js.src} src={js.src} />
            ))}
            {typeof window !== 'undefined' && withoutTrailing(window.location.pathname)
                ? <link rel="canonical" href={addTrailingSlash(urlJoin(window.location.origin, window.location.pathname))} />
                : null}
            {script
                ? (
                    <script key="script" dangerouslySetInnerHTML={{ __html: script }} />
                )
                : null}
            {jsonld
                ? (
                    <script type="application/ld+json">
                        {JSON.stringify(jsonld)}
                    </script>
                )
                : null}
            {noscript && (
                <noscript key="noscript" dangerouslySetInnerHTML={{ __html: noscript }} />
            )}
            <script src="https://cdn.moin.bz/widget.js" data-pid={ciaociaoID[locale]} />
        </Helmet>
    );
};

export default SEO;
