import React from 'react';
import { Global } from '@emotion/react';
import { GlobalStyles as BaseStyles } from 'twin.macro';

const GlobalStyles = () => (
    <>
        <BaseStyles />
        <Global />
    </>
);

export default GlobalStyles;
