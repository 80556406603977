import urlJoin from 'url-join';
import { siteUrl, ogImageLink } from '../config';

const imageUrl = ogImageLink;
const logoUrl = urlJoin(siteUrl, '/img/logo.png');

const blogAuthor = {
    '@type': 'Organization',
    name: 'moin',
    url: siteUrl,
    logo: {
        '@type': 'ImageObject',
        url: logoUrl,
        width: '250',
        height: '250',
    },
};

export const genOrganization = () => ({
    '@context': 'https://schema.org',
    '@type': 'Organization',
    url: siteUrl,
    logo: logoUrl,
});

export const genDefaultJsonLd = () => ([
    genOrganization(),
]);

export const genBlogJsonLd = ({
    url,
    headline,
    alternativeHeadline,
    image = imageUrl,
    inLanguage,
    articleBody = '',
    keywords,
    dateCreated,
    datePublished,
    dateModified,
    genre = ['Message', 'Feedback Plugin'],
}) => ({
    '@context': 'http://schema.org',
    '@type': 'BlogPosting',
    image,
    url,
    headline,
    alternativeHeadline,
    dateCreated,
    datePublished,
    dateModified,
    inLanguage,
    publisher: blogAuthor,
    keywords,
    genre,
    articleBody,
    mainEntityOfPage: url,
    author: {
        '@type': 'Organization',
        name: 'moin!',
        url: logoUrl,
    },
});

export const genBreadcrumbJsonLd = (items) => {
    const itemListElement = [];
    let count = 1;
    items.forEach((item) => {
        itemListElement.push({
            '@type': 'ListItem',
            position: count,
            name: item.name,
            item: item.url,
        });
        count += 1;
    });

    return {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        itemListElement,
    };
};
