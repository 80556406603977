const bowser = require('bowser');
const urlJoin = require('url-join');
const { siteUrl } = require('../config');

const allLocales = ['en', 'zh-hant', 'zh-hans'];
const localesMappging = {
    en: 'English',
    'zh-hant': '中文',
};

exports.localesMappging = localesMappging;
exports.macOrWindows = () => {
    if (typeof window !== 'undefined') {
        const detect = bowser._detect(window.navigator.userAgent);
        if (detect.windows || detect.windowsPhone) {
            return 'win';
        }
    }
    return 'mac';
};

exports.isMobile = () => bowser.ios || bowser.android || bowser.blackberry || bowser.firefoxos;

exports.isNotWin10 = () => {
    if (typeof window !== 'undefined') {
        const detect = bowser._detect(window.navigator.userAgent);
        if (detect.windows && (detect.osversion === '7' || detect.osversion === '8')) {
            return true;
        }
    }
    return false;
};

exports.isChina = () => {
    if (typeof window !== 'undefined' && navigator.languages.includes('zh-CN')) {
        return true;
    }
    return false;
};

exports.removeTrailing = (url) => url.replace(/\/+$/, '');

const withoutTrailing = (url) => url.slice(-1) !== '/';

exports.withoutTrailing = withoutTrailing;

const defaultLanguage = 'en';
exports.defaultLanguage = defaultLanguage;

const addTrailingSlash = (url) => {
    if (withoutTrailing(url)) {
        return `${url}/`;
    }
    return url;
};

exports.addTrailingSlash = addTrailingSlash;

exports.camelCased = (str) => str.replace(/-([a-z])/g, (g) => g[1].toUpperCase());
